import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import Button from "react-bootstrap/Button";
import ReactDom from 'react-dom'
import axios from "../../utils/axios";
import {
    InputRow, MuiText
} from 'components/RenewalStyledFields';

import useCommonStyles from 'components/CommonStyleClasses';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ThemedButton, ThemedButtonSimple } from 'components/GCButtons';

const CancelButton = styled(Button)`
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 40px;
        width: 120px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    `;

const MODAL_STYLES = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#FFF',
    padding: '50px',
    zIndex: 1000
}
const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000
}

const ReferralModal = ({ open, onClose, tableData, isOpen }) => {
    const commonClasses = useCommonStyles();
    const classes = useStyles();
    const [formValue, setFormValue] = React.useState({
        account_number : tableData.account_number,
        account_id : tableData.account_id,
        account_start : tableData.account_start,
        account_status : tableData.account_status,
        account_transaction_id : tableData.account_transaction_id,
        amount_paid : tableData.amount_paid,
        bill_id : tableData.bill_id,
        created_by_username : tableData.created_by_username,
        UtilityName : tableData.UtilityName,
        transaction_date:tableData.transaction_date,
        ref_account_id: tableData.ref_account_id,
        ref_account_number:tableData.ref_account_number,
        ref_account_start: tableData.ref_account_start,
        ref_account_status: tableData.ref_account_status,
        a_type:tableData.a_type,
        referral_code: tableData.referral_code,
        raf_id:tableData.raf_id,
        a_case: tableData.a_case,
        a_id:tableData.a_id,
        raf_type: tableData.raf_type,
        needs_update:tableData.needs_update,
        needs_create: tableData.needs_create
    });
    const handleCancel = (e) => {
        onClose();
    }
    const handleSubmit = async (e) => {

    }
    const handleChange = (e) => {
        //console.log(e);

        setFormValue({
            ...formValue,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked === true ? 1 : 0 : e.target.value
        });

    }
    if (!open) {
        // console.log('not opened...');
        return null
    } else {
        console.log(isOpen);
        console.log(tableData);
    }
    return ReactDom.createPortal(
        <>
            <div key="OS1" style={OVERLAY_STYLES} />
            <div key="FM1" className={classes.modal}>
                <form>
                    <div className={commonClasses.flexColumn} style={{alignItems: "start"}}>
                        <Typography variant="h5" sx={{mb: 3, alignSelf: "center"}}>
                            {tableData.needs_create? "Create new Record" :
                                tableData.needs_update ? "Update record" : "Updated"}
                        </Typography>
                        {tableData.needs_create || tableData.needs_update ?
                        <> {/* Create  */}
                            <InputRow>
                                <MuiText
                                    label="Type"
                                    name="a_type"
                                    defaultValue={formValue.a_type}
                                    onChange={handleChange}
                                    key="a_type"
                                />

                                <MuiText
                                    label="Referral Code"
                                    name="referral_code"
                                    defaultValue={formValue.referral_code}
                                    onChange={handleChange}
                                    key="referral_code"
                                />
                            </InputRow>
                            <InputRow>
                                <MuiText
                                    label="Account Number"
                                    name="account_number"
                                    defaultValue={formValue.account_number}
                                    onChange={handleChange}
                                    key="account_number"
                                />

                                <MuiText
                                    label="Account Status"
                                    name="account_status"
                                    defaultValue={formValue.account_status}
                                    onChange={handleChange}
                                    key="account_status"
                                />
                            </InputRow>
                            <InputRow>

                                <MuiText
                                    label="Account Start"
                                    name="account_start"
                                    defaultValue={formValue.account_start}
                                    onChange={handleChange}
                                    key="account_start"
                                />
                                <MuiText
                                    label="Raf Id"
                                    name="raf_id"
                                    defaultValue={formValue.raf_id}
                                    onChange={handleChange}
                                    key="raf_id"
                                />

                            </InputRow>
                            <InputRow>
                                <MuiText
                                    label="Ref Account Number"
                                    name="account_number"
                                    defaultValue={formValue.ref_account_number}
                                    onChange={handleChange}
                                    key="account_number"
                                />

                                <MuiText
                                    label="Ref Account Status"
                                    name="account_status"
                                    defaultValue={formValue.ref_account_status}
                                    onChange={handleChange}
                                    key="account_status"
                                />
                            </InputRow>
                            <br/>
                            <InputRow>
                                <ThemedButton
                                    onClick={handleCancel}
                                    outlined={'true'}
                                    key="b1"
                                >
                                    Cancel
                                </ThemedButton>
                                <ThemedButton
                                    contained={'true'}
                                    onClick={handleSubmit}
                                    key="b2"
                                >
                                    Save
                                </ThemedButton>
                            </InputRow>
                        </>:<>
                                <InputRow>
                                    <MuiText
                                        label="Type"
                                        name="a_type"
                                        defaultValue={formValue.a_type}
                                        onChange={handleChange}
                                        key="a_type"
                                    />

                                    <MuiText
                                        label="Referral Code"
                                        name="referral_code"
                                        defaultValue={formValue.referral_code}
                                        onChange={handleChange}
                                        key="referral_code"
                                    />
                                </InputRow>
                                <InputRow>
                                    <MuiText
                                        label={"Account Number" + (formValue.a_type==='referer' ? " *" : "")}
                                        name="account_number"
                                        defaultValue={formValue.account_number}
                                        onChange={handleChange}
                                        key="account_number"
                                    />

                                    <MuiText
                                        label="Account Status"
                                        name="account_status"
                                        defaultValue={formValue.account_status}
                                        onChange={handleChange}
                                        key="account_status"
                                    />
                                </InputRow>
                                <InputRow>

                                    <MuiText
                                        label="Account Start"
                                        name="account_start"
                                        defaultValue={formValue.account_start}
                                        onChange={handleChange}
                                        key="account_start"
                                    />
                                    <MuiText
                                        label="Raf Id"
                                        name="raf_id"
                                        defaultValue={formValue.raf_id}
                                        onChange={handleChange}
                                        key="raf_id"
                                    />
                                </InputRow>
                                <InputRow>
                                    <MuiText
                                        label="Amount Paid"
                                        name="amount_paid"
                                        defaultValue={formValue.amount_paid}
                                        onChange={handleChange}
                                        key="amount_paid"
                                    />

                                    <MuiText
                                        label="Created By"
                                        name="created_by_username"
                                        defaultValue={formValue.created_by_username}
                                        onChange={handleChange}
                                        key="created_by_username"
                                    />
                                </InputRow>
                                <InputRow>
                                    <MuiText
                                        label={"Referrer Account Number" + (formValue.a_type==='referred' ? " *" : "")}
                                        name="ref_account_number"
                                        defaultValue={formValue.ref_account_number}
                                        onChange={handleChange}
                                        key="ref_account_number"
                                    />

                                    <MuiText
                                        label="Account Status"
                                        name="ref_account_status"
                                        defaultValue={formValue.ref_account_status}
                                        onChange={handleChange}
                                        key="ref_account_status"
                                    />
                                </InputRow>
                                <InputRow>

                                    <MuiText
                                        label="Account Start"
                                        name="ref_account_start"
                                        defaultValue={formValue.ref_account_start}
                                        onChange={handleChange}
                                        key="ref_account_start"
                                    />
                                    <MuiText
                                        label="Bill Id"
                                        name="bill_id"
                                        defaultValue={formValue.bill_id}
                                        onChange={handleChange}
                                        key="bill_id"
                                    />
                                </InputRow>
                                * Customer that received the credit
                                <br/>
                                <ThemedButton
                                    onClick={handleCancel}
                                    outlined={'true'}
                                    key="b1"
                                >
                                    Close
                                </ThemedButton>

                        </>}
                    </div>
                </form>
            </div>
        </>
        ,
        document.getElementById('portal')
    )
};
export default ReferralModal;

const useStyles = makeStyles((theme) => ({
    modal: {
        position: 'fixed',
        width:
            "55%",
        height:
            "96vh",
        overflowX:
            "hidden",
        top:
            '50%',
        left:
            '50%',
        transform:
            'translate(-50%, -50%)',
        backgroundColor:
            '#FFF',
        borderRadius:
            10,
        padding:
            '60px 8vw',
        zIndex:
            1000,
        [theme.breakpoints.down("lg")]
    :
        {
            width: "70%",
        }
    ,
        [theme.breakpoints.down("md")]
    :
        {
            width: "90%",
        }
    }
,
    forCheckButton: {
        display: 'flex',
            flexDirection
    :
        'row',
            justifyContent
    :
        'flex-start',
            alignItems
    :
        'center',
            backgroundColor
    :
        'none',
            padding
    :
        '0px 0px 20px 0px',
            cursor
    :
        'pointer',
            position
    :
        'absolute',
    }
,
    buttonLinks: {
        margin: 0,
        fontWeight: 'bold',
        position: 'relative',
        transition: `all ease-in-out 0.3s`,
        "&:hover": {
            fontSize: '18px',
        }
    }
}))