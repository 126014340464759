import { Box, Button, CircularProgress, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowRight from '@mui/icons-material/ArrowForwardIos';
import Theme from "theme/theme";

export const ThemedButton = (props) => {
    const classes = useGCButtonStyles({
        contained: props.contained,
        width: props.width,
        animation: true
    })
    return (
        <>
            <button
                onClick={props.onClick}
                type={props.type || "button"}
                className={classes.btnMain}
                {...props}
            >
                <div className={classes.btnContainer2}>
                    <div className={classes.btnMainTextContainer}
                        style={{
                            boxShadow: props.contained ? "rgb(255 90 106 / 50%) 0px 9px 40px" : 'none',
                        }}
                    >
                        <span className={classes.btnText}>{props.children}</span>
                        {(props.contained || props.src) &&
                            (
                                <SvgIcon
                                    component={props.src || ArrowRight}
                                    style={{ color: props.contained ? "white" : Theme.palette.primary.main }}
                                    className="button-icon"
                                />
                            )
                        }
                    </div>
                </div>
            </button>
            <style>{`
                .${classes.btnMainTextContainer}:hover, .${classes.btnMainTextContainer}:focus {
                    
                }
                .${classes.btnMainTextContainer} .${classes.btnText} {
                    transition: all 0.25s ease 0s;
                }
                .${classes.btnMainTextContainer} .button-icon {
                    opacity: 0;
                    position: absolute;
                    right: -20%;
                    transition: all 0.30s ease 0s;
                }
                .${classes.btnMainTextContainer}:hover .button-icon, .${classes.btnMainTextContainer}:focus .button-icon{
                    right: 0%;
                    opacity: 1;
                }
                .${classes.btnMainTextContainer} p{
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                }
                @media (max-width:900px) {
                    .${classes.btnMainTextContainer} .${classes.btnText}, .${classes.btnMainTextContainer} .${classes.btnText} {
                        transform: translateX(-12px);
                    }
                    .${classes.btnMainTextContainer} .button-icon {
                        opacity: 1;
                    }
                }
        `}</style>
        </>
    )
}

export const ThemedButtonSimple = (props) => {
    const classes = useGCButtonStyles({
        contained: props.contained,
        width: props.width,
        animation: true
    })
    return (
        <>
            <button
                onClick={props.onClick}
                type={props.type || "button"}
                className={classes.btnMain}
                {...props}
            >
                <div className={classes.btnContainer2}>
                    <div className={classes.btnMainTextContainer}
                        style={{
                            boxShadow: props.contained ? "rgb(255 90 106 / 50%) 0px 9px 40px" : 'none',
                        }}
                    >
                        <span className={classes.btnText}>{props.children}</span>
                    </div>
                </div>
            </button>
            <style>{`
                .${classes.btnMainTextContainer}:hover, .${classes.btnMainTextContainer}:focus {
                    
                }
                .${classes.btnMainTextContainer} .${classes.btnText} {
                    transition: all 0.25s ease 0s;
                }
                .${classes.btnMainTextContainer} .button-icon {
                    opacity: 0;
                    position: absolute;
                    right: -20%;
                    transition: all 0.30s ease 0s;
                }
                .${classes.btnMainTextContainer}:hover .button-icon, .${classes.btnMainTextContainer}:focus .button-icon{
                    right: 0%;
                    opacity: 1;
                }
                .${classes.btnMainTextContainer} p{
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                }
                @media (max-width:900px) {
                    .${classes.btnMainTextContainer} .${classes.btnText}, .${classes.btnMainTextContainer} .${classes.btnText} {
                        transform: translateX(-12px);
                    }
                    .${classes.btnMainTextContainer} .button-icon {
                        opacity: 1;
                    }
                }
        `}</style>
        </>
    )
}

function getAni(props) {
    console.log(props)
    return "28px";
}

export const useGCButtonStyles = makeStyles((theme) => ({
    btnMainTextContainer: {
        position: "relative",
        textDecoration: "none",
        fontWeight: "600",
        fontStyle: "normal",
        fontSize: 16,
        lineHeight: "24px",
        padding: "1.125rem 2.125rem",
        textAlign: "center",
        justifyContent: "space-around",
        display: "flex",
        flexBasis: 200,
        flexGrow: 1,
        flexShrink: 0,
        alignItems: "center",
        width: "100%",
        height: 48,
        marginRight: 0,
        transition: "all 0.25s ease 0s",
        '&:hover': {
            // marginRight: 28,
        },
        [theme.breakpoints.down("md")]: {
            fontSize: 16,
            lineHeight: "24px",
            padding: "0.75rem 2.125rem",
        }
    },
    btnMain: {
        overflow: "hidden",
        borderRadius: 45,
        border: "1px solid #FF5A6A",
        backgroundColor: props => props["contained"] ? "#FF5A6A" : "#fff",
        position: "relative",
        width: props => props["width"] ? props["width"] : "100%",
        height: 48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 0,
        [theme.breakpoints.down("md")]: {
            marginBottom: 20
        }
    },
    btnContainer2: {
        overflow: "hidden",
        '&:hover': {
            overflow: "unset"
        },
        height: 48,
        cursor: "pointer",
        fontSize: 16,
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 45,
    },
    btnText: {
        direction: "rtl",
        width: "100%",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: 18,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        lineHeight: "24px",
        color: props => props["contained"] ? "#fff" : "#FF5A6A",
        textDecoration: "none",
        [theme.breakpoints.down("md")]: {
            fontSize: 16
        }
    },
    submitBtn: {
        height: 48,
        width: 180,
        boxShadow: 3,
        "&.MuiButtonBase-root:hover": {
            background: theme.palette.primary.main,
        },
        [theme.breakpoints.up("md")]: {
            height: 60,
        },
    },
    btnContainer: {
        display: "flex",
        justifyContent: "space-around",
        zIndex: 3,
    },
    btnShadow: {
        "@media only screen and (-webkit-min-device-pixel-ratio: 1)": {
            '.ios.bg-overlay': {
                '-webkit-backdrop-filter': "blur('60px')"
            }
        },
        position: "absolute",
        // left: "12.43%",
        left: "11.89%",
        right: "11.89%",
        top: "50%",
        bottom: "-10%",
        background: theme.palette.primary.main,
        opacity: 1,
        filter: "blur(1.125rem)",
        borderRadius: "29px",
        zIndex: 1,
    },
}));
