import React, {useEffect, useState} from "react";
import axios from "../../utils/axios";
import jquery from "jquery"
// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Navbar,
    Nav,
    Table,
    Container,
    Row,
    Col,
    Form,
    OverlayTrigger, CardGroup, CardColumns,CardDeck

} from "react-bootstrap";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import Dropdown from "react-bootstrap/esm/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import WidgetModal from "../../components/modals/WidgetModal";

import CountDown from "assets/sixtysecs.gif";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Bar Chart',
        },
    },
};
const random = (min, max)=>{
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const periods = [
    {name: '15 Minutes' , period : '15i'},
    {name: '30 Minutes' , period : '30i'},
    {name: '1 Hour'     , period : '1h'},
    {name: '3 Hours'    , period : '3h'},
    {name: '6 Hours'    , period : '6h'},
    {name: '8 Hours'    , period : '8h'},
    {name: '12 Hours'    , period : '12h'},
    {name: '1 Day'      , period : '1d'},
    {name: '3 Day'      , period : '3d'},
    {name: '7 Day'      , period : '7d'},
    {name: 'Today'      , period : 'today'},
    ];
let elements = 27;
const data1 = [];
const data2 = [];
const data3 = [];
for (let i = 0; i <= elements; i++) {
    data1.push(random(50, 200))
    data2.push(random(80, 100))
    data3.push(65)
}
export const data = {
    labels,
    datasets: [
        {
            label: 'Dataset 1',
            data: data1,
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
            label: 'Dataset 2',
            data: data2,
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};
const openInit = {
    widget: false
}
function TechApplication01() {
    const refsecs = 60;
    const defaultTimerSeconds = 60000;

    const calculateTodayPeriod = () => {
        const now = new Date();
        const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const durationMs = now - startOfDay;
        const hours = Math.floor(durationMs / (1000 * 60 * 60));
        const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));
        return `${hours}h${minutes}m`;
    };

    const [currPeriod, setCurrPeriod] = useState(periods.find(p => p.name === 'Today'));
    const [loading, setLoading] = useState(true);
    const [alertsData, setAlertsData] = useState([]);
    const [alertsTitles, setAlertsTitles] = useState([]);
    const [widgetsData, setWidgetsData] = useState([]);
    const [hideNav, setHideNav] = useState(true);
    const [isOpen, setIsOpen] = useState(openInit);
    const [selectedWidget, setSelectedWidget] = useState({})
    const [counterImg, setCounterImg] = useState(CountDown)
    let timer = false;

    const loadNewPeriod = async (index) => {
        await setCurrPeriod(periods[index]);
    }
    const fetchAlerts = async () => {
        let periodParam = currPeriod.period;
        if (currPeriod.name === 'Today') {
            periodParam = calculateTodayPeriod();
        }
        //setLoading(true);
        const response = await axios.get(`/monitor/dashapp01?period=${periodParam}`,
            { headers: {'x-api-key' : process.env.REACT_APP_API_KEY}}
        );
        let alArrData = [];
        let alArrTitles = [];
        Object.keys(response.data.data).forEach((i)=>{
            //console.log(response.data.data[i].length === undefined)
            if (response.data.data[i].length === undefined) {
                //alArrData.push(response.data.data[i]);
                let alArrDet = [];
                Object.keys(response.data.data[i]).forEach((j)=>{
                    //console.log(response.data.data[i][j].length === undefined)
                    if (response.data.data[i][j].length === undefined) {
                        alArrDet.push(response.data.data[i][j]);
                    }
                    console.log(i,j,response.data.data[i][j]);
                    //console.log(alArrDet);
                },[alArrDet]);
                alArrData.push(alArrDet);
                alArrTitles.push(i);
            }
        },[alArrData,alArrTitles]);
        //console.log(alArrData);
        //console.log(alArrTitles);
        await setAlertsData(alArrData);
        await setAlertsTitles(alArrTitles);
        let wgArrData = [];
        Object.keys(response.data.data2.db.widgets).forEach((i)=>{
            wgArrData.push(response.data.data2.db.widgets[i]);
        },[wgArrData]);
        Object.keys(response.data.data2.db2.widgets).forEach((i)=>{
            wgArrData.push(response.data.data2.db2.widgets[i]);
        },[wgArrData]);

        await setWidgetsData(wgArrData);
        setLoading(false);
        await setCounterImg(null)
        setCounterImg(CountDown)
    };
    const init = async () => {
        setLoading(true);
        await fetchAlerts();
    }
    useEffect(() => {
        init().then(
            () => {
                console.log('loaded');
            }
        );
        const intervalId2 = setInterval(() => {
            fetchAlerts(); // Fetch data every 2 minutes
        }, defaultTimerSeconds);
        return () => {
            //clearInterval(intervalId)
            clearInterval(intervalId2);
        };
    }, [currPeriod]);
    if (! loading) {

    }
    if (hideNav) {
        //jquery('#acc_tittle').hide();
        //jquery('#mainNav').hide();
        //setHideNav(false);
    }
    jquery(document).click(()=>{
        if (hideNav) {
            jquery('#acc_tittle').hide();
            jquery('#mainNav').hide();
            setHideNav(false);
        } else {
            jquery('#acc_tittle').show();
            jquery('#mainNav').show();
            setHideNav(true);
        }
        console.log('*');
    });
    const handleClose = () => {
        setSelectedWidget({});
        setIsOpen(openInit);
    };
    const doSetSelectedWidget = async (aWidget) => {
        await setSelectedWidget(aWidget);
        //console.log(aWidget.object.Image);
        await setIsOpen({...openInit, widget: true});
    }
    const handleClick = (p)=>{
        if (p) {
            p.preventDefault();
            console.log('received');
            /*console.log(p.target.attributes);
            console.log(alertsData[p.target.attributes.datarow.value][p.target.attributes.datacol.value]);*/
            //console.log(widgetsData);

            let search = alertsData[p.target.attributes.datarow.value][p.target.attributes.datacol.value].Metric;
            let aWidget = [];
                aWidget = widgetsData.find((a)=>{
                    console.log('a.title==search');
                    console.log(a.title==search);
                    console.log('a.title===search');
                    console.log(a.title===search);
                if (a.title===search) {return true} else {return false}
                //console.log(a)
            })
            //console.log(search);
            //console.log(aWidget);
            if (aWidget) {
                doSetSelectedWidget(aWidget);
            }
        }
    }
    jquery(document).ready(()=>{
        /*document.body.style.transform = "scale(.75)";*/
    });
    return (
        <> {
            (loading || ! alertsData || alertsData.length <=0 ) ? (
                <>
                    loading
                </>
            ):(
                <>
                    <Container fluid>
                        <Row>
                            {
                                alertsData.map((b, i) => {
                                    return (
                                        <CardGroup
                                            style={{
                                                borderStyle: 'dotted',
                                                borderWidth: 'thin',
                                                padding: 1,
                                                margin: 0,
                                                flexFlow: 'wrap',
                                                minWidth: '99%',
                                                maxWidth: '20%'
                                            }}
                                            key={"CG-" + i}
                                        >

                                            <div className="numbers h3"
                                                 style={{
                                                     minWidth: '99%',
                                                     maxWidth: '99%',
                                                     maxHeight: '3%'
                                                 }}
                                                 key={"title-" + i}
                                            >
                                                <p className="card-category">{alertsTitles[i]}</p>
                                            </div>

                                            {((typeof b) === 'object') ?
                                                b.map((a, ii) => {
                                                    return (<Col style={{
                                                            minWidth: '16.6%',
                                                            maxWidth: '16.6%',
                                                            padding: 0,
                                                            margin: 0
                                                        }} key={"Col-" + i + "-" + ii}>
                                                            <Card
                                                                className="card-stats"
                                                                key={"Card-" + i + "-" + ii}

                                                            >
                                                                <Card.Body
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0
                                                                    }}
                                                                >
                                                                    <div className="numbers">
                                                                        <p className="card-category">{a.Metric}</p>
                                                                        <p className="card-category">{a.Descrption ?? ''}</p>
                                                                        <Card.Title
                                                                            className={a.Class}
                                                                        >
                                                                            <Card.Link
                                                                                key={`k${i}${ii}`}
                                                                                className={a.Class}
                                                                                datarow={i}
                                                                                datacol={ii}
                                                                                onClick={handleClick}
                                                                            >
                                                                                {a.Status}
                                                                            </Card.Link>
                                                                        </Card.Title>
                                                                    </div>
                                                                </Card.Body>
                                                            </Card>
                                                        </Col>
                                                    )
                                                }) : ''
                                            }
                                        </CardGroup>
                                    )
                                })
                            }
                        </Row>
                        <Row>
                            <CardGroup
                                style={{
                                    borderStyle: 'dotted',
                                    borderWidth: 'thin',
                                    padding: 5,
                                    margin: 2,
                                    flexFlow: 'wrap',
                                    minWidth: '98%',
                                    maxWidth: '20%'
                                }}
                            >
                                <Card></Card>
                                <Card>
                                    <Card.Body>
                                        <h1>Period: {currPeriod.name}
                                            <DropdownButton variant="secondary" className="ml-auto" drop="left" title="Period">
                                                {periods.map((aPeriod, index) => (
                                                    <Dropdown.Item as="button" key={'v' + index} value={index}
                                                                   onClick={() => loadNewPeriod(index)}>{aPeriod.name}</Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </h1>
                                        <Card.Img
                                            title={"CountDown"}
                                            src={counterImg}
                                            bsPrefix={"img"}
                                            width={"80px!important"}
                                            id={"CountDown"}
                                        ></Card.Img>
                                    </Card.Body>
                                </Card>
                                <Card></Card>
                            </CardGroup>
                        </Row>
                    </Container>
                    <div id="portal"></div>
                    <WidgetModal
                        open={isOpen.widget}
                        onClose={handleClose}
                        widget={selectedWidget}
                    ></WidgetModal>
                </>
            )}
        </>
    );
}

export default TechApplication01;