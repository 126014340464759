import React from "react";
import { NavLink } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import Button from "react-bootstrap/Button";
import { useMsal } from "@azure/msal-react";

/**
 * Renders a dropdown button with child buttons for logging in with a popup or redirect
 */
export const MenuButtons = (props) => {
    const {instance, accounts} = useMsal();
    const disablePrices = !accounts.some(
        (v) => v.username.match(/(supple|lawrence|hernandez|weilbacher|mudgil|jones)/i)
    );
    const navigationGroups = [
        {id: 'Download', name: 'Internal App Download', objectIdd: '4150eb14-670f-4c53-8e06-4477947cdff3'},
        {id: 'User', name: 'Internal app Users', objectIdd: '8bc4b63b-eefb-4a87-9706-4e64094f6b6b'},
        {id: 'Admin', name: 'Internal app Administrators', objectIdd: 'defb2d35-66c8-43bd-bf63-59da31d5d5fb'}
    ];
    const navigationData = [
        {
            type: 'DDB', title: 'Report', groups: ['User'], items: [
                {title: 'Sales Report', route: '/sales-report'}
            ]
        },
        {
            type: 'DDB', title: 'Brokers', groups: ['User'], items: [
                {title: 'Broker Management', route: '/broker-mnt'},
                {title: 'Broker Product List', route: '/broker-product-list'},
                {title: 'API Enrollments', route: '/broker-api-enrollments'},
                {title: 'API Incomplete', route: '/broker-api-incomplete'},
                {title: 'Broker API Info', route: '/broker-api-docs'},
                {title: 'Broker Signup Enrollments', route: '/broker-enrollments'},
                {title: 'Broker Signup Incomplete', route: '/broker-incomplete'}
            ]
        },
        {
            type: 'DDB', title: 'Enrollments', groups: ['User'], items: [
                {title: 'Incomplete', route: '/incomplete'},
                {title: 'Enrollments', route: '/enrollments'},
                {title: 'Fraud Review', route: '/fraud-review'},
                {title: 'Enrollment Portal', route: '/enrollment-portal'},
                {title: 'New Enrollment', route: '/new-enrollment'},
                {title: 'Saves Report', route: '/dropped'},
                {title: 'QS Dashboard', route: '/enrollments/qs-db-04'}
            ]
        },
        {
            type: 'DDB', title: 'Accounts', groups: ['User'], items: [
                {title: 'Lookup', route: '/account/lookup'},
                {title: 'Pending MyAccount', route: '/account/pending-myaccount'},
                {title: 'Billing', route: '/account/accountbilling'},
                {title: 'Account by ESIID', route: '/account/accountnumesiid'},
            ]
        },
        {
            type: 'DDB', title: 'ReferAFriend', groups: ['User'], items: [
                {title: 'Referrals', route: '/referrals'},
            ]
        },
        {
            type: 'DDB', title: 'Renewals', groups: ['User'], items: [
                {title: 'Renewals', route: '/renewals'},
                {title: 'QS Dashboard', route: '/renewals/qs-db-03'},
                {title: 'QS Analysis', route: '/renewals/qs-db-06'},
                {title: 'Renewal Notice', route: '/settings/renewalnotice'}
            ]
        },
        {
            type: 'DDB', title: 'Partners', groups: ['User'], items: [
                {title: 'Partners List', route: '/partner-list'}
            ]
        },
        {
            type: 'DDB', title: 'Accounting', groups: ['User'], items: [
                {title: 'QS Tax Reconciliation', route: '/accounting/qs-db-05'}
            ]
        },
        {
            type: 'DDB', title: 'Tools', groups: ['User'], items: [
                {title: 'Generate Email Signature', route: '/generate-email-signature'},
                {title: 'Enrollment Settings', route: '/settings/enrollment', disabled: disablePrices},
                {title: 'PromoCodes', route: '/promocodes'},
                {title: 'SMS Templates', route: '/sms-templates'},
                {title: 'Maintenance Banners', route: '/maintenance-banners'},
                {title: 'Check Email Validity', route: '/settings/checkemail'},
                {title: 'Tdu for Account', route: '/settings/tduforaccount'}
            ]
        },
        {
            type: 'DDB', title: 'VideoVet', groups: ['User'], items: [
                {title: 'Chat Log', route: '/videovet'}
            ]
        },
        {
            type: 'DDB', title: 'Tech', groups: ['User'], items: [
                {title: 'App DashBd 01', route: '/tech/dashapp01'},
                {title: 'Hdw DashBd 01', route: '/tech/dashhdw01'},
                {title: 'Tickets EH Review', route: '/tech/tk-review/eh'},
                {title: 'Tickets QW Review', route: '/tech/tk-review/qw'}
            ]
        }
    ];
    const userGroups = [];
    accounts[0].idTokenClaims.groups.map((g) => {
        navigationGroups.map((n) => {
            if (n.objectIdd === g) {
                userGroups.push(n.id);
            }
        });
    });
    const userHasGroup = (g) => {
        return g.some((i) => {
                return userGroups.includes(i)
            }
        );
    }
    return (
        navigationData.map((menuItem) => {
                switch (menuItem.type) {
                    case 'DDB':
                        return (userHasGroup(menuItem.groups) ?
                                <DropdownButton
                                    key={menuItem.title}
                                    title={menuItem.title}
                                    variant={menuItem.variant ?? 'danger'}
                                    className={menuItem.className ?? 'ml-auto'}
                                    drop={menuItem.drop ?? 'left'}
                                >
                                    {menuItem.items.map((menuSubItem) => {
                                            return (
                                                <NavLink
                                                    key={menuSubItem.title}
                                                    href={menuSubItem.route}
                                                >
                                                    <Dropdown.Item as={Button}>{menuSubItem.title}</Dropdown.Item>
                                                </NavLink>
                                            )
                                        }
                                    )}
                                </DropdownButton> : ''
                        )
                        break;
                }
            }
        )
    );
}